// @ts-nocheck
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { MenuItemLink, MenuProps } from "react-admin";
import {
  Tooltip,
  InteractiveTooltip,
} from "@my-scoot/component-library-legacy";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import {
  MENU_ITEM_VALUES_CONFIG,
  TRIGGER_RESIZE_THROTTLE_TIME,
} from "features/UILayout/modules/Menu/constants/Menu.constants";
import styles from "features/UILayout/modules/Menu/MenuStyles.module.css";
import {
  UI_LAYOUT__DECREMENT_ACTIVE_TOOL_TIP_INDEX,
  UI_LAYOUT__INCREMENT_ACTIVE_TOOL_TIP_INDEX,
} from "features/UILayout/redux/UILayout.actions";
import { useDesktopMediaQuery } from "features/Common/hooks/useDesktopMediaQuery";
import { triggerResizeAndScroll } from "features/Common/utils/common.utils";
import { OnboardingTooltipFooter } from "../OnboardingTooltipFooter/OnboardingTooltipFooter";
import { findParentMenuKey } from "../../utils/Menu.utils";
import classnames from "classnames";
import { useOnboardingTooltip } from "../OnboardingTooltip/utils/useOnboardingTooltip";

export const CustomMenuItemLink = withComponentLibraryTheme(
  (props: MenuProps) => {
    const {
      primaryText,
      id,
      showTooltip = false,
      handleToggleParent = () => {},
      ...restProps
    } = props;

    const dispatch = useDispatch();
    const isDesktop = useDesktopMediaQuery();
    const { endOnboardingTooltipTour } = useOnboardingTooltip();
    const {
      isToolTipTourActive,
      activeTooltipIndex,
      visibleConfig: config,
    } = useSelector((state) => state.uiLayout);

    const TooltipWrapper =
      showTooltip && !isToolTipTourActive ? Tooltip : "div";

    const onNext = ({ id }) => {
      handleToggleParent({ id, forward: true });
      dispatch({ type: UI_LAYOUT__INCREMENT_ACTIVE_TOOL_TIP_INDEX });
      triggerResizeAndScroll({
        id,
        throttleTime: TRIGGER_RESIZE_THROTTLE_TIME,
      });
    };

    const onPrev = ({ id }) => {
      handleToggleParent({ id });
      dispatch({ type: UI_LAYOUT__DECREMENT_ACTIVE_TOOL_TIP_INDEX });
      triggerResizeAndScroll({
        id,
        throttleTime: TRIGGER_RESIZE_THROTTLE_TIME,
      });
    };

    const isOpen = isToolTipTourActive && config[activeTooltipIndex] === id;

    return (
      <InteractiveTooltip
        onCloseClick={endOnboardingTooltipTour}
        open={isOpen}
        title={
          MENU_ITEM_VALUES_CONFIG[config[activeTooltipIndex]]?.tooltipTitle
        }
        customFooter={
          <OnboardingTooltipFooter
            onNext={() => onNext({ id })}
            hidePrevBtn={isDesktop && activeTooltipIndex === 0}
            onPrev={() => onPrev({ id })}
          />
        }
        totalSteps={0}
        enterDelay={0}
        leaveDelay={0}
        placement={isDesktop ? "right-start" : "bottom"}
        tooltipClasses={{
          popper: isToolTipTourActive
            ? styles.interactiveToolTipPopper
            : styles.unsetInteractiveToolTipPopper,
        }}
        {...(config[activeTooltipIndex] === id && {
          className: isToolTipTourActive && styles.tooltipContainer,
        })}
      >
        <TooltipWrapper
          title={primaryText}
          placement="right"
          color="primary"
          arrow
          id={id}
        >
          <MenuItemLink
            primaryText={props.sidebarIsOpen ? primaryText : ""}
            {...(findParentMenuKey(id) && {
              className: classnames(
                styles.subMenu,
                isToolTipTourActive &&
                  config[activeTooltipIndex] !== id &&
                  styles.hidebefore
              ),
            })}
            {...restProps}
          />
        </TooltipWrapper>
      </InteractiveTooltip>
    );
  }
);
