import React from "react";
import { Button } from "@my-scoot/component-library-legacy";
import NavigateBeforeSharpIcon from "@material-ui/icons/NavigateBeforeSharp";
import NavigateNextSharpIcon from "@material-ui/icons/NavigateNextSharp";
import styles from "./OnboardingTooltipFooterStyles.module.css";
import classnames from "classnames";

export const OnboardingTooltipFooter = ({
  onNext = () => {},
  onPrev = () => {},
  primaryBtnText = "Next",
  hideNextBtn = false,
  hidePrevBtn = false,
}) => {
  return (
    <div className={styles.footerWrapper}>
      <div className={styles.navIconsWrapper}>
        <NavigateBeforeSharpIcon
          className={classnames(styles.icon, {
            [styles.activeIcon]: !hidePrevBtn,
          })}
          onClick={onPrev}
        />
        <NavigateNextSharpIcon
          className={classnames(styles.icon, {
            [styles.activeIcon]: !hideNextBtn,
          })}
          onClick={onNext}
        />
      </div>
      <Button className={styles.btn} onClick={onNext}>
        {primaryBtnText}
      </Button>
    </div>
  );
};
